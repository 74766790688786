export const NEW_YORK_MIDTOWN_TESTIMONIALS = [
	{
		text: `Adrian was absolutely amazing to work with and clearly knows what he’s doing. Most lawyers had told us it would be impossible for us to start a company on H1B but Adrian clearly laid out what was needed and how to get there. Would recommend all founders and startups with immigration needs go with Adrian.`,
		name: 'Kanishk Vashisht',
		stars: 5,
	},

	{
		text: `Adrian was absolutely wonderful to work with. It was so very clear that he cared about my case and he brought a balance of strong legal advice and conversational troubleshooting to fit the specificity of my situation. I waited till after we completed a retainer agreement before writing this, as I noticed that some of the reviews are for the initial consultation. Having worked with him and his team through setting up my business in compliance with legal and immigration laws, I would 10/10 highly recommend his service!`,
		name: 'Aniekeme Umoh',
		stars: 5,
	},

	{
		text: `Our company enjoyed a great deal of professionalism by Mr Pandev and his team in our corporate non-immigrant visa application process. I recommend Pandev Law's office as they are knowledgeable, reliable and punctual!`,
		name: 'Smart Management Group',
		stars: 5,
	},

	{
		text: `Used the services offered by Pandev Law to start a limited liability company and the entire experience was seamless to say the least. Very quick follow-ups from the team and they happily answer any and all questions you ask them with a perfect amount of depth. Would highly recommend them for any of your legal needs!`,
		name: 'Karan Narang',
		stars: 5,
	},

	{
		text: `You will be hard-pressed to find any service that is better than Pandev Law. They are extremely professional - the website is easy to navigate while packed with valuable information, you can easily schedule a call, and everyone is very reassuring, helpful, and responsive. Most importantly, they are able to get things done extremely efficient - it took them just about a week to get my LLC all up and running. I highly recommend them!`,
		name: 'Khanh Nguyen',
		stars: 5,
	},

	{
		text: `Adrian and his team helped me through my OPT and STEM OPT requirements seamlessly. Given I am working on my own startup and am an international student, it was a complicated case and Adrian made it look so simple. Cannot recommend him enough!`,
		name: 'Jai Thirani',
		stars: 5,
	},

	{
		text: `Booked a consultation with Adrian. He was very welcoming while maintaining a professional demeanor. He was honest and up-front about the process and the likelihood of outcomes in my specific situation. There was no rush to make a decision regarding my case, and he did not press me to pursue any services with the practice, but did inform me of all my options from there. Would highly recommend going for a consultation.`,
		name: 'SG Rodriguez',
		stars: 5,
	},

	{
		text: `I am so happy to have worked with Adrian Pandev and his team on my E-2 visa! I personally have nightmares thinking about any legal matters and Pandev Law just makes this job easy and even pleasant! Very professional and knowledgeable, he handled my case perfectly with no problems or complications. Would recommend him any time!`,
		name: 'Velina Miteva',
		stars: 5,
	},

	{
		text: `I’ve searched far and wide for lawyers who are experienced in OPT VISAs, specifically how USCIS actually processes employment, and Adrian is by far the most knowledgeable and was able to answer all of my questions and concerns within the first 15 minutes of our consultation!`,
		name: 'Mick Eamsureya',
		stars: 5,
	},

	{
		text: `Everything that we could have hoped for and needed! With a single one hour consultation Adrian resolved our legal concerns, our sleepless nights, and all our anxieties. Our experience was worth every penny, and we would highly recommend his services to everyone!`,
		name: 'Cica Mica',
		stars: 5,
	},
];
