import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';
import {
  NEW_YORK_MIDTOWN_PHONE,
  NEW_YORK_MIDTOWN_PHONE_DISPLAY,
} from '../data/site-data';
import { NEW_YORK_MIDTOWN_TESTIMONIALS } from '../data/new-york-midtown/testimonials';
import OfficeMeta from '../components/contact/office-meta';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';

const headMeta = {
  title: 'New York Immigration Lawyer Near Me | NYC Midtown | Pandev Law',
  description:
    'Searching for a trusted and experienced Immigration Lawyer near you in Midtown, New York? We help with visas, green cards, naturalization and work permits. Talk to us now!',
  keywords:
    'New York immigration lawyer,immigration lawyer near me,immigration lawyer NYC',
};

const schema = `{
  "@context": "http://schema.org",
  "@type": "LegalService",
  "name": "New York Immigration Lawyer Near Me | NYC Midtown | Pandev Law",
  "description": "Searching for a trusted and experienced Immigration Lawyer near you in Midtown, New York? We help with visas, green cards, naturalization and work permits. Talk to us now!",
  "url": "https://www.pandevlaw.com/new-york-immigration-lawyer",
  "image": "https://pandevlaw.com/img/ny-office-inner.jpg",
  "priceRange": "$$",
  "telephone": "${NEW_YORK_MIDTOWN_PHONE_DISPLAY}",
  "email": "adrian@pandevlaw.com",
  "hasMap": "https://www.google.com/maps/place/Pandev+Law/@40.747441,-73.9740783,17z/data=!4m5!3m4!1s0x0:0xa3899d545e150452!8m2!3d40.7493064!4d-73.9759129",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "New York",
    "addressRegion": "NY",
    "postalCode": "10016",
    "streetAddress": "600 Third Avenue, Floor 2"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "40.749495",
    "longitude": "-73.975911"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  },
  "sameAs": [
    "https://en-gb.facebook.com/pandevlaw/"
  ],
  "openingHours": "Mo,Tu,We,Th,Fr, 8:30am-5:30pm"
}`;

function NYMidtownOffice({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <StaticImage
        className="img-fluid d-lg-none"
        src="../assets/img/ny-office.jpg"
        alt="New York immigration lawyer near me"
      />
      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <StaticImage
                className="img-fluid d-none d-lg-inline-block"
                src="../assets/img/ny-office-inner.jpg"
                alt="New York immigration lawyer near me"
                placeholder="none"
                loading="eager"
              />
            </div>
            <div className="col-lg-7 pl-lg-6">
              <h1 className="text-hero mb-5 mb-md-8">
                New York Immigration Lawyer - Midtown
              </h1>
              <div className="d-flex mb-5">
                <div>
                  <p className="text-lead text-grey mb-3">Call us at:</p>
                  <a
                    href={`tel:${NEW_YORK_MIDTOWN_PHONE}`}
                    className="phone-new-york-downtown text-phone cta-green">
                    <i className="fas fa-phone fa-flip-horizontal icon-phone" />
                    {NEW_YORK_MIDTOWN_PHONE_DISPLAY}
                  </a>
                  <p className="text-lead text-grey mt-3">For a consultation</p>
                </div>
              </div>
              <div className="mb-5">
                <p className="text-mega font-size-24 mb-4">As featured in:</p>
                <div className="d-flex align-items-center">
                  <a
                    href="https://profiles.superlawyers.com/new-york/new-york/lawyer/adrian-pandev/04411de3-60c5-4ace-aa12-e98485d9be2b.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      src="../assets/img/superlaweyrs.png"
                      alt="Super Lawyers - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.globallegalinsights.com/firms/pandev-law-llc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      quality={100}
                      src="../assets/img/globallegalinsights.png"
                      alt="Global Legal Insights - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.managehrmagazine.com/pandev-law-llc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={150}
                      quality={100}
                      src="../assets/img/pandevlaw-award-logo.png"
                      alt="Manage HR Magazine"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.americastop50lawyers.com/product-page/adrian-m-pandev-america-s-top-50-lawyers-immigration-law-new-york"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={120}
                      quality={100}
                      src="../assets/img/america-top-50-lawyers.png"
                      alt="America Top 50 Lawyers"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </div>
              </div>
              <p className="text-body">
                If you are searching for “immigration lawyer near me”, you are at the
                right place. Pandev Law&#39;s New York immigration law office is easily
                accessible from Grand Central Terminal in Midtown East. For the
                convenience of international clients, our New York immigration lawyer is
                located just a few blocks from the United Nations Headquarters as well as
                numerous consulates and NGOs. So if you are searching for an “immigration
                attorney near me,” we are conveniently located to help you.
                <br />
                <br />
                <strong>&ldquo;The City that Never Sleeps&rdquo;</strong> is the global
                capital of international commerce, finance, media, art, fashion,
                technology and entertainment. As the largest and most multicultural city
                in the United States, New York has forever been home to&nbsp;countless
                foreign nationals and businesses. We are proud of our reputation as a
                go-to New York immigration law firm serving the City’s large immigrant and
                international business communities.
                <br />
                <br />
                Our New Yok immigration lawyer represents individuals and organizations in
                various industries, including technology, e-commerce, engineering,
                academia, fashion, healthcare, consulting, biotechnology, food and
                beverage, research, and construction industries. Your search for
                “immigration lawyer near me” has brought you to the right place. If you
                are seeking effective business or immigration representation, contact
                Pandev Law’s NYC immigration law office and inquire about our immigration
                lawyer New York services.
              </p>
            </div>
          </div>
        </div>
      </div>

      <AbTestingDeck
        position="top"
        title="New York Midtown Client Reviews"
        testimonials={NEW_YORK_MIDTOWN_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0 order-lg-12">
              <StaticImage
                placeholder="blurred"
                className="img-fluid lazy"
                src="../assets/img/new-york-office-outside.jpg"
                alt="Immigration Lawyer New York"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <p className="text-body">
                <strong>Immigration Lawyer New York</strong>
                <br />
                Our immigration lawyer NYC services focuses on
                <a href="/investor-visa-usa-immigration">USA investor visa</a>,
                <a href="/family-based-green-card-immigration">
                  family-based immigration
                </a>
                , and
                <a href="/employment-based-visa-immigration">
                  employment-based immigration
                </a>
                , particularly <a href="/eb-5-visa">EB-5 Investors</a> and
                <a href="/e-2-visa">E-2 Treaty Investors</a>, H-1B Specialty Workers, and{' '}
                <a href="/l-1-visa">L-1 Intracompany Transferees</a>. If you are looking
                for an “immigration attorney near me,” we have the experience to help you
                in your immigration case. We also regularly advise startups and
                established organizations regarding visa sponsorship for foreign
                employees. Our immigration attorney NYC services include immigration
                compliance, such as Form I-9 and E-Verify compliance. In addition, we have
                extensive experience with the employment-based permanent residence
                process, including PERM Labor Certifications, EB-2 National Interest
                Waivers, and EB-1 petitions for Multinational Managers and Individuals of
                Extraordinary Ability, including academics, researchers, scientists,
                artists, and athletes. If you are searching for a “business immigration
                lawyer near me” or an “employment-based immigration lawyer near me,” we
                have the experience and know- how to help you in your immigration case.
                Contact us to inquire about our immigration lawyer NYC services.
                <br />
                <br />
                <strong>Immigration Lawyer NYC</strong>
                <br />
                Are you searching for a “family-based immigration lawyer near me” to help
                you sponsor a visa or green card for a family member? We have extensive
                experience uniting families through marriage, fiance, and other
                family-based immigration petitions. Contact us to inquire about our
                immigration lawyer NYC services.
              </p>
            </div>
          </div>
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <StaticImage
                placeholder="blurred"
                className="img-fluid lazy"
                src="../assets/img/new-york-office-inside.jpg"
                alt="Teacher, Professor, and Researcher Immigration Attorney NYC"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-body">
                <strong>
                  Teacher, Professor, and Researcher Immigration Attorney NYC
                </strong>
                <br />
                Are you a teacher, professor, researcher or an educational institution
                seeking an “immigration lawyer near me”? We specialize in representing
                educational institutions, including primary schools and colleges, in
                immigration related matters. Contact us to inquire about our immigration
                attorney NYC services.
                <br />
                <br />
                <strong>International Business Lawyer New York</strong>- Our business law
                firm focuses on advising entrepreneurs, small, and medium sized businesses
                in formation and governance, mergers and acquisitions, commercial
                transactions, and financing, including venture capital. We have extensive
                experience representing foreign companies entering the US market and
                regularly advise on cross-border transactions. Our NYC international
                business lawyer advises on import and export compliance and helps obtain
                relief for alleged customs law violations. We represent foreign blockchain
                startups and cryptocurrency investors who wish to move their business to
                the US.
                <br />
                <br />
                Whether you are searching for “immigration lawyer near me”, “international
                business lawyer near me,” or “immigration attorney near me,” you have come
                to the right place. Contact Pandev Law’s New York office for all your
                business or immigration law needs.
              </p>
              <p className="text-body mt-5">
                Our New York – Midtown immigration office is by appointment only.
              </p>
            </div>
          </div>
        </div>
      </div>

      <OfficeMeta
        googleReviewsCount={95}
        googleRating={5.0}
        officeTitle={
          <>
            New York
            <br />
            Immigration Office
          </>
        }
        addressLine1="600 Third Avenue, Floor 2"
        addressLine2="New York, NY 10016"
        telephone={NEW_YORK_MIDTOWN_PHONE}
        displayTelephone={NEW_YORK_MIDTOWN_PHONE_DISPLAY}
        mapCenter={{
          lat: 40.749495,
          lng: -73.975911,
        }}
        googleBusinessLink="https://www.google.bg/maps/place/Pandev+Law/@40.7493064,-73.9781016,17z/data=!3m1!4b1!4m7!3m6!1s0x89c25903f44b4e85:0xa3899d545e150452!8m2!3d40.7493064!4d-73.9759129!9m1!1b1"
      />

      <AbTestingDeck position="bottom" />
      <PilarNavigation />
      <CtaDeck />
    </Layout>
  );
}

export default NYMidtownOffice;
